import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import AnchorLink from 'src/components/atoms/AnchorLink/AnchorLink';
import ScrollingMouse from 'src/components/atoms/ScrollingMouse/ScrollingMouse';
import Section from 'src/components/grid/Section/Section';
import Heading from 'src/components/shared/Heading/Heading';
import withScrolling, {
  IWithScrollingInjectedProps,
} from 'src/hoc/withScrolling';
import styles from './HomeHero.module.scss';

interface IHomeHeroProps extends IWithScrollingInjectedProps {
  className?: string;
}

export const HomeHero: FunctionComponent<Partial<IHomeHeroProps>> = ({
  className,
  isScrolling,
}) => {
  return (
    <Section
      data-test="component-home-hero"
      className={classNames(styles.homeHero, className)}
      style={{
        backgroundImage: `url(
          ${process.env.GATSBY_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-content/uploads/2019/08/185963530_medium.jpg
          )`,
      }}
    >
      <div className={styles.homeHeroContent}>
        <Heading
          priority={1}
          className={styles.homeHeroHeading}
          innerHTML={false}
        >
          Let's{' '}
          <Link to={`/services/graphic-design`}>
            <span className={styles.homeHeroHeadingHighlight}>
              desi<span className={styles.homeHeroHeadingStroke}>g</span>n
            </span>
          </Link>{' '}
          and{' '}
          <Link to={`/services/web-design-and-development`}>
            <span className={styles.homeHeroHeadingHighlight}>build</span>
          </Link>{' '}
          a website you'll love
        </Heading>
        <p className={styles.homeHeroCopy}>
          We specialise in web design & development, graphic design, visual
          branding, S.E.O, strategy planning and hosting.
        </p>
        <AnchorLink className={styles.homeHeroLink} link={'/services'}>
          View our services
        </AnchorLink>
      </div>

      <div className={styles.homeHeroReveal}>
        {!isScrolling && (
          <ScrollingMouse
            theme={'tint-psi'}
            className={styles.homeHeroScrollingMouse}
          />
        )}
        {isScrolling && (
          <FontAwesomeIcon icon={['fal', 'chevron-down']} size={'2x'} />
        )}
      </div>
    </Section>
  );
};

HomeHero.displayName = 'HomeHero';

export default withScrolling(HomeHero);
