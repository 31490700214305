import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Brand from 'src/components/molecules/Brand/Brand';
import FeaturedProjects from 'src/components/organisms/FeaturedProjects/FeaturedProjects';
import { ImageScroller } from 'src/components/organisms/ImageScroller/ImageScroller';
import Testimonials from 'src/components/organisms/Testimonials/Testimonials';
import Heading from 'src/components/shared/Heading/Heading';
import * as coreActions from '../store/core/actions';
import CtaBanner from 'src/components/organisms/CtaBanner/CtaBanner';
import FeaturedServices from 'src/components/organisms/FeaturedServices/FeaturedServices';
import HomeHero from 'src/components/organisms/HomeHero/HomeHero';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import {
  IAcfModule,
  IAppState,
  IMenuOpen,
  IPageContext,
  IStateDispatch,
} from 'src/utils/interfaces';

export class UnconnectedIndexPage extends PureComponent<Partial<IPageContext>> {
  async componentDidMount(): Promise<void> {
    const { onGetSiteMeta, onGetSiteOptions } = this.props;
    await onGetSiteMeta();
    await onGetSiteOptions();
  }

  render() {
    const { location } = this.props;

    return (
      <>
        <CoreLayout location={location} title="Welcome to Pixel Twist">
          <HomeHero/>

          <FeaturedServices module={featuredServicesModule}/>

          <ImageScroller
            size={'md'}
            image={{
              image: `${process.env.GATSBY_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-content/uploads/2019/07/2018.jpg`,
            }}
          >
            <Brand
              theme={'tint-alpha'}
              type={'symbol'}
              className="mb-2"
              center
            />
            <p
              style={{
                fontSize: '2rem',
              }}
              className="text--tint-alpha"
            >
              PixelTwist provided us with an amazing website that really fits
              our brand.
            </p>
            <Heading
              style={{ letterSpacing: '1px' }}
              priority={4}
              className="mb-0 mt-2 text--tint-alpha text--bold"
            >
              Gipsy Hill Brewing Company
            </Heading>
          </ImageScroller>

          <FeaturedProjects contrast={true} pageTheme={'tint-alpha'}/>

          <Testimonials pageTheme={'tint-beta'}/>

          <CtaBanner/>
        </CoreLayout>
      </>
    );
  }
}

const featuredServicesModule: Partial<IAcfModule> = {
  contrast: false,
  heading: 'Our Services',
  copy: `We cover all web and design related services.
        <br/>
        Which means we can help from project start to project completion.`,
  services: [],
};

export default connect(
  ({ core: { isMenuOpen } }: IMenuOpen) => ({
    isMenuOpen,
  }),
  (dispatch: ThunkDispatch<IAppState, IStateDispatch, AnyAction>) => ({
    onGetSiteMeta: () => dispatch(coreActions.getSiteMeta()),
    onGetSiteOptions: () => dispatch(coreActions.getSiteOptions()),
  })
)(UnconnectedIndexPage);
