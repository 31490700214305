import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { TTheme } from 'src/utils/types';

import styles from './ScrollingMouse.module.scss';

interface IScrollingMouse {
  className?: string;
  theme: TTheme;
}

const ScrollingMouse: FunctionComponent<Partial<IScrollingMouse>> = (
  {
    className,
    theme = 'tint-alpha',
  },
) => {
  return (
    <div
      data-test="component-scrolling-mouse"
      className={classNames(styles['mouse-wrapper'], className)}
    >
      <div
        className={classNames(
          styles.mouse,
          styles[`mouse--border--${theme}`],
        )}
      >
        <div
          className={classNames(
            styles['mouse__scroll-wheel'],
            styles[`mouse--theme--${theme}`],
          )}
        />
      </div>
    </div>
  );
};

export default ScrollingMouse;
